<template>
  <div class="login-vue">
    <div class="logotop">
      <img src="../../assets/images/logo_title.png" alt="" />
      <!-- <div class="logo_text">运维管家自动监测管理后台</div> -->
      <div class="logo_text">智慧在线运维平台</div>
    </div>
    <div class="login-container">
      <div class="login-content">
        <div class="login-title">
          {{ config.appName }}
        </div>
        <div v-if="message">
          <el-alert :title="message" type="error" :closable="false"></el-alert>
        </div>
        <div class="login-name login-input">
          <input
            type="text"
            name="account"
            v-model="form.account"
            autocomplete="off"
          />
          <span
            class="placeholder"
            :class="{ fixed: form.account != '' && form.account != null }"
          >
            用户名
          </span>
        </div>
        <div class="login-password login-input">
          <input
            type="password"
            name="password"
            v-model="form.password"
            autocomplete="off"
          />
          <span
            class="placeholder"
            :class="{ fixed: form.password != '' && form.password != null }"
          >
            密码
          </span>
        </div>
        <div class="buttonDiv">
          <el-button
            class="h-btn"
            type="primary"
            :loading="loading"
            @click="submit"
          >
            登录
          </el-button>
        </div>
      </div>
    </div>

    <div class="record">
      地址: 珠海市金湾区红旗镇智造大街3栋402 Copyright © 2019恒星环保版权所有
      <a style="color: #3646b6;" href="http://beian.miit.gov.cn"
        >粤ICP备15105890号</a
      >
    </div>
    <div class="fscode">
      <!-- <img src="../../assets/images/fsenv_code.png" alt="" /> -->
      <img src="../../assets/images/fsenv_code.png" alt="" />
      <div>运维管家小程序二维码</div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import md5 from "js-md5";
import config from "src/config";
import { apiAccountLogin } from "src/api/index";
export default {
  data() {
    return {
      form: {
        account: "",
        password: ""
      },
      message: "",
      loading: false
    };
  },
  mounted() {},
  computed: mapState({
    config: state => state.config
  }),
  methods: {
    submit() {
      const _this = this;
      _this.loading = true;
      apiAccountLogin(_this.form)
      .then(res => {
        _this.message = "";
        _this.loading = false;
        _this.$localStorage.set(md5("session"), res.session);
        _this.$router.push(config.homeRoute);
      })
      .catch(err => {
        _this.loading = false;
        _this.$message.error(err.message);
      });
    }
  }
};
</script>

<style lang="less">
@gradient-color: #3788ee;
@bg-color: #f7f8fa;
@title-color: #3a3a3a;
@text-color: #7e7e7e;
@placeholder-color: #7e7e7e;

.login-vue {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: @bg-color;
  .login-container {
    width: 360px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .login-content {
      letter-spacing: 2px;
      background: #fff;
      padding: 70px 30px 20px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      box-sizing: border-box;
      > div {
        margin: 30px 0;
        &.login-input {
          position: relative;
          .placeholder {
            position: absolute;
            color: @placeholder-color;
            top: 6px;
            font-size: 16px;
            transition: all 0.2s;
            left: 0;
            pointer-events: none;
          }
          input {
            font-size: 16px;
            padding: 8px 0;
            height: 40px;
            width: 100%;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #d3d3d3;
            box-shadow: inset 0 0 0 1000px #fff;
            outline: none;
            box-sizing: border-box;
            transition: 0.3s;
            font-weight: 200;
            &:focus {
              border-bottom-color: @gradient-color;
              box-shadow: inset 0 0 0 1000px #fff;
            }
          }
          input:focus + .placeholder,
          .placeholder.fixed {
            font-size: 13px;
            top: -16px;
          }
          input:-webkit-autofill + .placeholder {
            font-size: 13px;
            top: -16px;
          }
        }
        &.login-title {
          font-size: 30px;
          color: @title-color;
          line-height: 1;
          margin: -16px 0px 40px;
          font-weight: 200;
        }
      }
      > .buttonDiv {
        margin-top: 45px;
        .h-btn {
          padding: 12px 0;
          width: 100%;
          font-size: 18px;
          opacity: 0.8;
          border-radius: 3px;
          background: @gradient-color;
          border-color: @gradient-color;
          &:hover {
            opacity: 1;
            background: @gradient-color;
            border-color: @gradient-color;
          }
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.login-vue {
  background: url("../../assets/images/loginbg.jpg") center no-repeat;
  background-size: 100% 100%;

  .logotop {
    position: fixed;
    top: 42px;
    left: 92px;
    display: flex;
    align-items: center;
    .logo_text {
      color: #fff;
      font-size: 20px;
      padding: 2px 20px;
      margin-left: 20px;
      letter-spacing: 1.5px;
      opacity: 0.95;
      border-left: 1px solid rgb(245, 245, 245);
    }
  }

  .h-panel {
    width: 380px;
    background: rgba(244, 244, 244, 0.25);
    border-radius: 20px 0 20px 0;

    .h-panel-body {
      padding: 0 30px 30px 30px;
      .login-tit {
        text-align: center;
        padding: 20px 0 22px 0;
        font-size: 18px;
        color: #ffffff;
      }
      .inputbox {
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 28px;
        i {
          font-size: 19px;
          padding: 4px 0 4px 15px;
        }
      }
      .subbut {
        width: 100%;
        background: #2684fa;
        text-align: center;
        border: none;
        border-radius: 4px;
        padding: 13px 0;
      }
    }
  }

  .record {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    font-size: 12px;
    color: #f1f1f1;
    text-align: center;
    padding: 40px 0;
    letter-spacing: 1.5px;
  }
  .fscode {
    position: fixed;
    left: 120px;
    bottom: 40px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    img {
      width: 120px;
      margin-bottom: 8px;
      border-radius: 5px;
    }
    div {
      transform: scale(0.9);
    }
  }
}
</style>
